import React from 'react';
import {Pie} from '@ant-design/plots';
import {UseRealtonesDonutQuery} from "../../query_hooks/useRealtonesDonutQuery";
import {RealtonesDonutPropsType} from "../../atoms/internalDashboardAtoms";

const currencyMap: any = {43: '€', 46: '£', 142: '$'};

const RealtonesDonutChart = (props: RealtonesDonutPropsType) => {

    const {data} = UseRealtonesDonutQuery(props)

    return (
        <Pie
            angleField={'metric'}
            colorField={'dimension'}
            data={data.data}
            appendPadding={[10, 150, 0, 0]}
            radius={1}
            innerRadius={0.64}
            meta={{
                metric: {
                    formatter: (value) => {
                        let currency = currencyMap[data.currency];
                        let returnValue = ((value as number) || 0)?.toLocaleString('el-GR') + (currency ? ' ' + currency : '');
                        return returnValue;
                    }
                }
            }}
            width={500}
            height={400}
            padding={0}
            label={{
                type: 'inner',
                offset: '-50%',
                style: {
                    textAlign: 'center'
                },
                autoRotate: false,

                formatter: (datum, mappingData) => {
                    let currency = currencyMap[data.currency];
                    let formNumber = new Intl.NumberFormat('en-US', {notation: 'compact'});
                    let returnValue = currency + ' ' + formNumber.format(+datum.metric);
                    return returnValue;
                }
            }}
            statistic={{
                title: {
                    offsetY: -4,
                    style: {
                        fontSize: '18px'
                    }
                },
                content: {
                    offsetY: 4,
                    style: {
                        fontSize: '32px'
                    }
                }
            }}
            interactions={[
                {
                    type: 'element-selected'
                },
                {
                    type: 'element-active'
                },
                {
                    type: 'pie-statistic-active'
                }
            ]}
        />
    );
};

export default RealtonesDonutChart;
