import { Pie } from '@ant-design/plots';
import React, { memo } from 'react';
import currencyFormatter from '../../utils/currency_formatter';
import { RevenuesByOwnerType } from '../InternalDashboard';
import { Row, Spin, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';

type Props = {
    revenues?: RevenuesByOwnerType;
};

const TotalRevenuesPieMemo = memo(function TotalsPie({ revenues }: Props) {
    const data = [
        {
            owner: 'Digital Platforms',
            metric: revenues?.data
                .filter((datum) => datum.owner === 'Digital Platforms')
                .reduce((total, curr) => total + curr.revenue, 0)
        },
        {
            owner: 'Affiliate',
            metric: revenues?.data
                .filter((datum) => datum.owner === 'Affiliate')
                .reduce((total, curr) => total + curr.revenue, 0)
        },
        {
            owner: 'Brands',
            metric: revenues?.data
                .filter((datum) => datum.owner === 'Brands')
                .reduce((total, curr) => total + curr.revenue, 0)
        }
    ];

    if (!revenues) {
        return <></>;
    }

    return (
        <Tabs>
            <TabPane key={'net_revenue_pie_chart'} tab={'Totals Pie Chart '}>
                <Row justify={'center'} style={{ width: '100%' }}>
                    <Pie
                        data={data.filter((datum) => datum.metric !== 0)}
                        appendPadding={[10, 150, 0, 0]}
                        radius={1}
                        innerRadius={0.64}
                        colorField='owner'
                        angleField='metric'
                        width={500}
                        height={400}
                        padding={0}
                        statistic={{
                            title: {
                                offsetY: -4,
                                style: {
                                    fontSize: '18px'
                                }
                            },
                            content: {
                                offsetY: 4,
                                style: {
                                    fontSize: '32px'
                                }
                            }
                        }}
                        label={{
                            type: 'inner',
                            offset: '-50%',
                            style: {
                                textAlign: 'center'
                            },
                            autoRotate: false,

                            formatter: ({ metric }, mappingData) => currencyFormatter.format(metric)
                        }}
                        meta={{
                            metric: {
                                formatter: (value) => currencyFormatter.format(value)
                            }
                        }}
                    />
                </Row>
            </TabPane>
        </Tabs>
    );
});

const TotalRevenuesPie = ({ revenues }: Props) => {
    const data = [
        {
            owner: 'Digital Platforms',
            metric: revenues?.data
                .filter((datum) => datum.owner === 'Digital Platforms')
                .reduce((total, curr) => total + curr.revenue, 0)
        },
        {
            owner: 'Affiliate',
            metric: revenues?.data
                .filter((datum) => datum.owner === 'Affiliate')
                .reduce((total, curr) => total + curr.revenue, 0)
        },
        {
            owner: 'Brands',
            metric: revenues?.data
                .filter((datum) => datum.owner === 'Brands')
                .reduce((total, curr) => total + curr.revenue, 0)
        }
    ];

    if (!revenues) {
        return <></>;
    }

    return (
        <Tabs>
            <TabPane key={'net_revenue_pie_chart'} tab={'Totals Pie Chart '}>
                <Row justify={'center'} style={{ width: '100%' }}>
                    <Pie
                        data={data.filter((datum) => datum.metric !== 0)}
                        appendPadding={[10, 150, 0, 0]}
                        radius={1}
                        innerRadius={0.64}
                        colorField='owner'
                        angleField='metric'
                        width={500}
                        height={400}
                        padding={0}
                        statistic={{
                            title: {
                                offsetY: -4,
                                style: {
                                    fontSize: '18px'
                                }
                            },
                            content: {
                                offsetY: 4,
                                style: {
                                    fontSize: '32px'
                                }
                            }
                        }}
                        label={{
                            type: 'inner',
                            offset: '-50%',
                            style: {
                                textAlign: 'center'
                            },
                            autoRotate: false,

                            formatter: ({ metric }, mappingData) => currencyFormatter.format(metric)
                        }}
                        meta={{
                            metric: {
                                formatter: (value) => currencyFormatter.format(value)
                            }
                        }}
                    />
                </Row>
            </TabPane>
        </Tabs>
    );
};

export default TotalRevenuesPieMemo;
