import axiosClient from "../api/axiosClient";
import {notification} from "antd";

// const fileExtensionMap: any = { 'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.ppt',
//     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx'};

const downloadPresentation = async (fileId: string, fileName: string, mimeType: string) => {
    try {

        const file = await axiosClient.portal.get(`/api/files/download/presentation/${fileId}`, {
            params:{
                mimeType: mimeType,
            },
            responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([file.data]));
        const link = document.createElement('a');
        link.href = url;

        // const downloadedFileName = `${fileName}` + fileExtensionMap[mimeType];
        const downloadedFileName = `${fileName}` + '.ppt';

        link.setAttribute('download', downloadedFileName);
        document.body.appendChild(link);

        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading file:', error);
        // Handle the error, e.g., show a notification to the user
        notification.error({ message: 'Error downloading file' });
    }
}

export default downloadPresentation;