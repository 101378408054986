import React, { Component } from 'react';
import { Badge, ConfigProvider, notification, Space, Tooltip } from 'antd';
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { EditableProTable } from '@ant-design/pro-table';
import enUSIntl from 'antd/es/locale/en_US';
import { EyeOutlined } from '@ant-design/icons';
import { postRequest } from '../api/postRequest';
import DeleteReport from './DeleteReport';
import axiosClient from '../api/axiosClient';

export interface IStatisticsReportsProps {
    //Here we pass the Props Interface
    channel_id: string;
    date_statistics: string;
    refStatisticsChannelsTable: React.RefObject<ActionType>;
    refStatisticsTable: React.MutableRefObject<ActionType | undefined>;
}

export interface IStatisticsReportsState {
    //Here we pass the State Interface

    //? means it can be undefined
    editableKeys: any;
    dataSource?: Channel[];
    // loading: boolean
    refStatisticsReportsTable: React.RefObject<ActionType>;
}

export type Channel = {
    id: string;
    name: string;
    url: string;
    approved: boolean;
};

export class StatisticsReports extends Component<IStatisticsReportsProps, IStatisticsReportsState> {
    constructor(props: IStatisticsReportsProps) {
        super(props);

        this.state = {
            editableKeys: [],
            dataSource: undefined,
            // loading: false,
            refStatisticsReportsTable: React.createRef<ActionType>()
        };
    }

    componentWillMount() {}

    // After the component did mount, we set the state.
    //On component start
    componentDidMount() {}

    columns: ProColumns<Channel>[] = [
        {
            title: 'Report Name',
            dataIndex: 'name',
            width: '50%',
            editable: false,
            render: (dom, entity) => {
                return (
                    <Tooltip title='View' color={'#595959'}>
                        <a href={entity.url} target={'_blank'} style={{ color: '#434343' }}>
                            {entity.name}
                        </a>
                    </Tooltip>
                );
            }
        },
        {
            title: 'Status',
            dataIndex: 'approved',
            align: 'center',
            width: '25%',
            render: (dom, entity) => {
                if (entity.approved == true) {
                    // return <Tag icon={<CheckCircleOutlined />} color="success">Approved</Tag>
                    return <Badge color='lime' text='Approved' />;
                } else if (entity.approved == false) {
                    // return <Tag icon={<CloseCircleOutlined />} color="error">Not Approved</Tag>
                    return <Badge color='#ff7875' text='Not Approved' />;
                }
            }
        },
        {
            title: '',
            align: 'center',
            render: (_, report) => (
                <Space>
                    <Tooltip title='View' color={'#595959'}>
                        <a href={report.url} target={'_blank'}>
                            {/*<RightCircleTwoTone style={{fontSize: '22px'}}/>*/}
                            <EyeOutlined style={{ color: '#262626' }} />
                        </a>
                    </Tooltip>

                    <DeleteReport
                        id={report.id}
                        name={report.name}
                        refStatisticsReportsTable={this.state.refStatisticsReportsTable}
                        refStatisticsChannelsTable={this.props.refStatisticsChannelsTable}
                        refStatisticsTable={this.props.refStatisticsTable}
                        key={report.id}
                    />
                </Space>
            )
        }
    ];

    render() {
        return (
            <ConfigProvider locale={enUSIntl}>
                <EditableProTable<Channel>
                    request={async (params, sort, filter) => {
                        try {
                            const res = await axiosClient.portal.post(
                                '/api/youtubechannels/getreports/' + this.props.channel_id,
                                {
                                    dateStatistics: this.props.date_statistics
                                }
                            );
                            // console.log(res);
                            return { data: res.data.params, success: true, total: res.data.length };
                        } catch (e: any) {
                            notification.error({ message: e.response.data.message });
                            return { data: [], success: false, total: 0 };
                        }
                    }}
                    columns={this.columns}
                    rowKey='id'
                    key={this.props.channel_id + '_' + this.props.date_statistics}
                    //Το βάζω false αν δεν θέλω να προσθέτει γραμμή
                    recordCreatorProps={false}
                    actionRef={this.state.refStatisticsReportsTable}
                />
            </ConfigProvider>
        );
    }
}
