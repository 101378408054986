import {useQuery} from "react-query";
import {RealtonesDonutPropsType} from "../../src/atoms/internalDashboardAtoms";
import axiosClient from "../api/axiosClient";

export function UseRealtonesDonutQuery (props: RealtonesDonutPropsType) {
    return useQuery( {
        queryKey: ["charts", "donut", "realtones", props],
        queryFn: async () =>
            await axiosClient.portal.post('/api/graphs/donut/realtones', props)
                .then(({data}) => data)
                .catch((reason) => console.error(reason)),
        suspense: true,
    })
}