import { useAtomValue } from 'jotai';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import { Models } from '../../common/models';
import { ProCard } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { Button, Input, Space, Statistic, Tabs, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import currencyFormatter from '../../utils/currency_formatter';
import TabPane from 'antd/es/tabs/TabPane';
import { FiltersType, useCustomerInvoicesFilteredQuery } from '../../atoms/externalDashboardAtoms';
import { isInvoiceTableEnabledAtom } from '../../atoms/internalDashboardAtoms';

type Props = {
    filters: FiltersType;
};

const InvoicesTable = ({ filters }: Props) => {
    const { data: invoicesData, refetch } = useCustomerInvoicesFilteredQuery(filters);

    const incomingColumns: ProColumns<Models.Brands.LoggedInvoiceIncoming>[] = [
        {
            title: 'Case',
            dataIndex: 'project_pretty_title',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder={`Search Invoice`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type='primary'
                            onClick={() => {
                                confirm();
                            }}
                            icon={<SearchOutlined />}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters(); // re ti kanoume
                                setSelectedKeys([]);
                                confirm();
                                close();
                            }}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Clear
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            onFilter: (value, record) =>
                record
                    .project_title!.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()),
            // valueType: 'select'
            sorter: (a, b) => (a.project_pretty_title ?? '').localeCompare(b.project_pretty_title ?? ''),
        },
        {
            title: 'Date Issued',
            dataIndex: 'date',
            valueType: 'date',
            render: (text, record, index) => {
                return dayjs(record.date).format('YYYY-MM-DD');
            },
            sorter: (a, b) => dayjs(a.date).diff(dayjs(b.date))
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            valueEnum: {
                YES: <Tag color='green'>Yes</Tag>,
                NO: <Tag color='red'>No</Tag>,
                PARTIALLY: <Tag color='yellow'>Partially</Tag>
            },
            onFilter: (value, record) => record.paid === value,
            filters: [
                {
                    text: 'Yes',
                    value: 'YES'
                },
                {
                    text: 'Partially',
                    value: 'PARTIALLY'
                },
                {
                    text: 'No',
                    value: 'NO'
                }
            ]
        },
        {
            title: 'Value',
            dataIndex: 'cost',
            align: 'right',
            renderText(text, record, index, action) {
                return currencyFormatter.format(record.cost);
            },
            sorter: (a, b) => a.cost - b.cost
        },
        {
            title: 'Value + VAT',
            dataIndex: 'cost_incl_vat',
            align: 'right',
            renderText(text, record, index, action) {
                return currencyFormatter.format(record.cost_incl_vat);
            },
            sorter: (a, b) => a.cost_incl_vat - b.cost_incl_vat
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paid_amount',
            align: 'right',
            renderText(text, record, index, action) {
                return currencyFormatter.format(record.paid_amount);
            },
            sorter: (a, b) => a.paid_amount - b.paid_amount
        }
    ];

    return (
        <ProTable
            columns={incomingColumns}
            dataSource={invoicesData}
            // request={async () => {
            //     const { data } = useInvoicesIncomingAtomValue();
            //     return data;
            // }}
            pagination={{
                pageSize: 10
            }}
            toolBarRender={false}
            search={false}
        />
    );
};

const Statistics = ({ filters }: Props) => {
    console.log('filters in Statistics component: ', filters);
    const { data: invoicesData } = useCustomerInvoicesFilteredQuery(filters);
    type LoggedInvoice = Models.Brands.LoggedInvoiceIncoming | Models.Brands.LoggedInvoiceOutgoing;

    const total = invoicesData.reduce((total: number, datum: LoggedInvoice) => total + datum.cost, 0);
    const total_with_vat = invoicesData.reduce(
        (total: number, datum: LoggedInvoice) => total + datum.cost_incl_vat,
        0
    );
    const total_paid_amount = invoicesData.reduce(
        (total: number, datum: LoggedInvoice) => total + datum.paid_amount,
        0
    );

    return (
        <ProCard split='vertical'>
            <ProCard>
                <Statistic title='Total Net Value' value={currencyFormatter.format(total)} />
            </ProCard>
            <ProCard>
                <Statistic title='Total Value + VAT' value={currencyFormatter.format(total_with_vat)} />
            </ProCard>
            <ProCard>
                <Statistic title='Total Paid + VAT' value={currencyFormatter.format(total_paid_amount)} />
            </ProCard>
            <ProCard>
                <Statistic
                    title='Total Unpaid + VAT'
                    value={currencyFormatter.format(total_with_vat - total_paid_amount)}
                />
            </ProCard>
        </ProCard>
    );
};

const BrandsInvoicesListExternal = ({ filters }: Props) => {
    const enabled = useAtomValue(isInvoiceTableEnabledAtom);
    if (!enabled) {
        return <></>;
    }
    return (
        <Tabs>
            <TabPane tab={'Brands Invoices'}>
                <ProCard split='horizontal' ghost>
                    <ProCard style={{ marginBottom: '1%' }} subTitle={'Totals'}>
                        <Statistics filters={filters} />
                    </ProCard>
                    <ProCard>
                        <InvoicesTable filters={filters} />
                    </ProCard>
                </ProCard>
            </TabPane>
        </Tabs>
    );
};

export default BrandsInvoicesListExternal;
