import React from 'react';
import {FiltersType} from '../atoms/internalDashboardAtoms';
import dayjs from 'dayjs';
import {PageContainer} from '@ant-design/pro-layout';
import {Row, Space, Tabs} from 'antd';
import {ProForm, ProFormDateRangePicker, ProFormRadio, ProFormSelect} from '@ant-design/pro-components';
import TabPane from 'antd/es/tabs/TabPane';
import {Column, Pie} from '@ant-design/plots';

type Props = {
    filters: FiltersType;
    type: 'INTERNAL' | 'EXTERNAL';
};

const ColumnGraphLoading = () => {
    return <Column data={[{x: 1, y: 1}]} xField='x' yField='y' loading/>;
};

export const PieGraphLoading = () => {
    return <Pie data={[{x: 1, y: 1}]} angleField='x' colorField='y' loading/>;
};

const DashboardLoading = ({filters, type}: Props) => {
    const currDate = new Date();

    return (
        <PageContainer>
            <>
                <Row>
                    <ProForm<FiltersType>
                        layout={'inline'}
                        initialValues={filters}
                        // submitter={false}
                        submitter={{
                            resetButtonProps: false,
                            submitButtonProps: {style: {marginLeft: 8}, disabled: true}
                        }}
                    >
                        <Space align={'baseline'}>
                            <ProFormRadio.Group
                                hidden={type === 'EXTERNAL'}
                                disabled
                                name='metricType'
                                label='Revenue'
                                radioType={'button'}
                                initialValue={'Customer'}
                                options={[
                                    {
                                        label: 'DM',
                                        value: 'Dm'
                                    },
                                    {
                                        label: 'Customers',
                                        value: 'Customer'
                                    }
                                ]}
                            />

                            <ProFormSelect
                                hidden={type === 'EXTERNAL'}
                                name='customers'
                                label='Select Customer'
                                placeholder=""
                                disabled
                                style={{width: '440px'}}
                                fieldProps={{
                                    mode: 'multiple',
                                    autoClearSearchValue: false,
                                    maxTagCount: 'responsive'
                                }}
                            />

                            <ProFormSelect
                                hidden={type === 'EXTERNAL'}
                                name='tags'
                                label='Select by Tag'
                                placeholder=""
                                disabled
                                style={{width: '400px'}}
                                fieldProps={{
                                    mode: 'multiple',
                                    autoClearSearchValue: false,
                                    maxTagCount: 'responsive'
                                }}
                            />

                            <ProFormDateRangePicker
                                width='md'
                                name='monthRange'
                                disabled
                                initialValue={[
                                    currDate.getFullYear() -
                                    1 +
                                    '-' +
                                    (currDate.getMonth() + 1 < 10 ? '0' : '') +
                                    (currDate.getMonth() + 1) +
                                    '-01',
                                    currDate.getFullYear() +
                                    '-' +
                                    (currDate.getMonth() + 1 < 10 ? '0' : '') +
                                    (currDate.getMonth() + 1) +
                                    '-01'
                                ]}
                                fieldProps={{
                                    format: 'YYYY-MM',
                                    picker: 'month',
                                    placeholder: ['Start month', 'End month'],
                                    value: [
                                        dayjs(filters.startDate, 'YYYY-MM'),
                                        dayjs(filters.endDate, 'YYYY-MM')
                                    ]
                                }}
                                transform={(values: string[]) => {
                                    return {
                                        startDate: values[0].substring(0, 7) + '-01' || undefined,
                                        endDate: values[1].substring(0, 7) + '-01' || undefined
                                    };
                                }}
                                style={{flex: 1}}
                            />
                        </Space>
                    </ProForm>
                </Row>

                <br/>

                <Tabs>
                    <TabPane tab={'...Loading'} disabled animated>
                        <ColumnGraphLoading/>
                    </TabPane>
                </Tabs>

                <br/>

                {/* <Tabs>
                    <TabPane tab={'...Loading'} disabled animated>
                        <PieGraphLoading />
                    </TabPane>
                </Tabs> */}
            </>
        </PageContainer>
    );
};

export default DashboardLoading;
