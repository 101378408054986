import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { Models } from '../common/models';
import axiosClient from '../api/axiosClient';
import { useQuery } from 'react-query';
import qs from 'qs';
import { atom, useAtomValue } from 'jotai';
import { SegmentedValue } from 'antd/es/segmented';
import dayjs from 'dayjs';

export type FiltersType = {
    startDate: string;
    endDate: string;
    customers: string[];
    internalSuppliers: number[];
    externalSuppliers: number[];
    internalCustomers: number[];
    externalCustomers: number[];
    metricType: 'Dm' | 'Customer';
};

export const selectedPodioIdAtom = atom<SegmentedValue>('');
export const formRefAtom = atom<any>(null);
export const customersAtom = atom<
    Array<{
        customer_id: number;
        customer_name: string;
    }>
>([]);

export const dateRangeAtom = atom<{
    start_date?: string;
    end_date?: string;
}>({});

export const filtersAtom = atom<FiltersType>({
    startDate: dayjs()
        .year(dayjs().get('year') - 1)
        .format('YYYY-MM-01'),
    endDate: dayjs().format('YYYY-MM-01'),
    customers: [],
    internalSuppliers: [],
    externalSuppliers: [],
    internalCustomers: [],
    externalCustomers: [],
    metricType: 'Customer'
});

export const submittedFiltersAtom = atom<FiltersType>({
    startDate: dayjs()
        .year(dayjs().get('year') - 1)
        .format('YYYY-MM-01'),
    endDate: dayjs().format('YYYY-MM-01'),
    customers: [],
    internalSuppliers: [],
    externalSuppliers: [],
    internalCustomers: [],
    externalCustomers: [],
    metricType: 'Customer'
});

export const scopeAtom = atom<string>((get) => {
    const metricToScope = {
        Dm: 'outgoing',
        Customer: 'incoming'
    };
    const filters = useAtomValue(submittedFiltersAtom);
    return metricToScope[filters.metricType];
});

export const isInvoiceTableEnabledAtom = atom<boolean>(true);

export const customerProjectsOnExecutionUnfilteredAtom = atomWithSuspenseQuery<Models.Brands.Project[]>(
    (get) => ({
        queryKey: ['customer_api', 'projects', 'onexecution'],
        queryFn: async () =>
            await axiosClient.brands
                .get(`/customer_api/projects`)
                .then(({ data }) => data)
                .catch((reason) => {
                    console.error(reason);
                    return [];
                })
    })
);

export const useCustomerInvoicesFilteredQuery = (filters: FiltersType) => {
    let params: {
        date_issued_start: string;
        month_issued_end: string;
    } = {
        date_issued_start: filters.startDate,
        month_issued_end: filters.endDate
    };

    return useQuery({
        queryKey: ['customer_api', 'invoices', params],
        queryFn: async () =>
            await axiosClient.brands
                .get(`/customer_api/invoices`, {
                    params,
                    paramsSerializer: (params) => {
                        return qs.stringify(params, { arrayFormat: 'brackets' });
                    }
                })
                .then(({ data }) => data)
                .catch((reason) => console.error(reason)),
        suspense: true,
        cacheTime: 0,
        staleTime: 0
    });
};
