import { auth } from '../config/firebase';
import axios from 'axios';
import { notification } from 'antd';

const axiosClient = {
    portal: newAxios(process.env.REACT_APP_API!),
    brands: newAxios(process.env.REACT_APP_BRANDS_API!),
    main: newAxios(process.env.REACT_APP_PAYMENTS_API!),
    rbac: newAxios(process.env.REACT_APP_RBAC_API!)
};

function newAxios(url: string) {
    const newAxiosInstance = axios.create({
        baseURL: url,
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        paramsSerializer: {
            indexes: null
        },
        formSerializer: {
            indexes: true
        }
    });

    // Request interceptor for API calls
    //Use the below interceptor to make transformations to an axios request
    // before the request is sent to the API
    newAxiosInstance.interceptors.request.use(
        async (config) => {
            const access_token = await auth.currentUser?.getIdToken();
            config.headers.Authorization = `Bearer ${access_token}`;

            if (config.data && typeof config.data === 'object') {
                config.headers['Content-Type'] = 'application/json';
            }

            return config;
        },
        (error) => {
            notification.error({ message: 'Unexpected request error', description: error.message });
            Promise.reject(error);
        }
    );

    //Use the below interceptor to handle axios (request) errors

    newAxiosInstance.interceptors.response.use(
        (response) => response,
        async function (error) {
            // Error 😨
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                // console.log(error.response.data);
                let apiError = error.response.data as API.ApiError;
                // console.log(apiError.message);
                // notification.error({message: apiError.message})
                notification.error({
                    message: 'Error ' + error.response.status,
                    description: apiError.message
                });
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                //eg the backend was stopped
                // console.log(error.request.data); // doesnt work
                notification.error({ message: 'Connection error' }); // alt. Network Error
            } else {
                // Something happened in setting up the request and triggered an Error
                // console.log('Error', error.message);
                // notification.error({message: 'Unexpected response error', description: error.message})
            }

            return Promise.reject(error);
        }
    );

    return newAxiosInstance;
}

export default axiosClient;
