import React, { useRef, useState } from 'react';
import { Col, ConfigProvider, notification, Row, Space, Spin, Tag, Typography } from 'antd';
import { PageContainer, ActionType, ProColumns } from '@ant-design/pro-components';
import { ProFormDatePicker } from '@ant-design/pro-components';
import { EditableProTable } from '@ant-design/pro-table';
import enUSIntl from 'antd/es/locale/en_US';
import { StatisticsChannels } from './StatisticsChannels';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Pie } from '@ant-design/plots';
import moment from 'moment';
import { postRequest } from '../api/postRequest';
import dayjs from 'dayjs';
import axiosClient from '../api/axiosClient';

type Props = {};

type CustomerParams = {
    id: number;
    full_name: string;
    status: string;
};

type DonutChartCustomersData = {
    type: string;
    value: number;
};

type DonutChartChannelsData = {
    type: string;
    value: number;
};

type DataSourceType = {
    params: CustomerParams[];
    donut_chart_customers_data: DonutChartCustomersData[];
    donut_chart_channels_data: DonutChartChannelsData[];
};

const Statistics = (props: Props) => {
    //
    // Initialize State
    //
    const refStatisticsTable = useRef<ActionType>();
    const [dataSource, setDataSource] = useState<DataSourceType>();
    const [dateStatistics, setDateStatistics] = useState<string>(
        moment().subtract(1, 'month').format('YYYY-MM')
    );
    const [pageSize, setPageSize] = useState<number>(6);

    const columns: ProColumns<CustomerParams>[] = [
        { title: 'Customer ID', dataIndex: 'id', align: 'center' },
        { title: 'Customer Name', dataIndex: 'full_name', align: 'center' },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            render: (dom, entity) => {
                if (entity.status == 'informed') {
                    return (
                        <Tag icon={<CheckCircleOutlined />} color='success'>
                            Informed
                        </Tag>
                    );
                } else if (entity.status == 'partially_informed') {
                    return (
                        <Tag icon={<ExclamationCircleOutlined />} color='warning'>
                            Partially Informed
                        </Tag>
                    );
                } else {
                    return (
                        <Tag icon={<CloseCircleOutlined />} color='error'>
                            Uninformed
                        </Tag>
                    );
                }
            },
            //Filtering column
            filters: [
                {
                    text: 'Informed',
                    value: 'informed'
                },
                {
                    text: 'Partially Informed',
                    value: 'partially_informed'
                },
                {
                    text: 'Uninformed',
                    value: 'uninformed'
                }
            ],
            // specify the condition of filtering result
            // here is that finding status starts with `value`
            onFilter: (value, record) => record.status === value
        }
    ];

    return (
        <PageContainer>
            <ConfigProvider locale={enUSIntl}>
                <Row>
                    <ProFormDatePicker.Month
                        name='month'
                        label='Month'
                        fieldProps={{
                            format: 'YYYY-MM',
                            defaultValue: dayjs().subtract(1, 'month'),
                            onChange: async (value, dateString) => {
                                try {
                                    // const res = await axiosApiInstance.get('/api/youtubechannels/getcustomers', {
                                    const res = await axiosClient.portal.post(
                                        '/api/youtubechannels/getcustomers',
                                        {
                                            dateStatistics: dateString
                                        }
                                    );

                                    // Update dataSource
                                    setDataSource(res.data);
                                    // Update dateStatistics
                                    setDateStatistics(dateString);
                                } catch (e: any) {
                                    notification.error({ message: e.response.data.message });
                                    return { data: [], success: false };
                                }
                            }
                        }}
                    />
                </Row>
                <Row>
                    <EditableProTable<CustomerParams>
                        request={async (params, sort, filter) => {
                            try {
                                const res = await axiosClient.portal.post(
                                    '/api/youtubechannels/getcustomers',
                                    {
                                        dateStatistics: dateStatistics,
                                        keyword: params.keyword
                                    }
                                );

                                // Initialize dataSource
                                setDataSource(res.data);

                                return { data: res.data.params, success: true };
                            } catch (e: any) {
                                notification.error({ message: e.response.data.message });
                                return { data: [], success: false };
                            }
                        }}
                        columns={columns}
                        rowKey='id'
                        value={dataSource?.params}
                        // Pagination PageSize Fix (pagination fix, pagination bug fix)
                        pagination={{
                            pageSize,
                            hideOnSinglePage: false,
                            showQuickJumper: true,
                            pageSizeOptions: ['6', '10', '20', '50'], // Optional: Provide available page sizes
                            onShowSizeChange: (current, size) => setPageSize(size), // Update pageSize on change
                            showSizeChanger: true
                        }}
                        //Το βάζω false αν δεν θέλω να προσθέτει γραμμή
                        recordCreatorProps={false}
                        expandable={{
                            expandedRowRender: (record) => (
                                <StatisticsChannels
                                    customer_id={record.id}
                                    date_statistics={dateStatistics}
                                    refStatisticsTable={refStatisticsTable}
                                />
                            )
                        }}
                        options={{
                            search: { placeholder: 'Please enter keyword', allowClear: true }
                        }}
                        actionRef={refStatisticsTable}
                    />
                </Row>

                <br />
                <br />

                {dataSource != undefined ? (
                    <Row justify={'center'}>
                        <Col xl={12} lg={24}>
                            <Space direction={'vertical'} align={'center'}>
                                <Typography.Text
                                    type={'secondary'}
                                    style={{
                                        marginLeft: -140,
                                        fontSize: '20px'
                                    }}
                                >
                                    Customers
                                </Typography.Text>
                                <Pie
                                    data={dataSource.donut_chart_customers_data}
                                    angleField={'value'}
                                    colorField={'type'}
                                    // padding={20}
                                    color={['#b7eb8f', '#fffb8f', '#ffa39e']}
                                    style={{ width: 410, marginTop: -53 }}
                                    appendPadding={[0, 20, 0, 0]}
                                    radius={1}
                                    innerRadius={0.6}
                                    label={{
                                        type: 'inner',
                                        offset: '-50%',
                                        style: {
                                            textAlign: 'center'
                                        },
                                        autoRotate: false,
                                        content: '{value}'
                                    }}
                                    statistic={{
                                        title: {
                                            offsetY: -4,
                                            style: {
                                                fontSize: '18px'
                                            }
                                        },
                                        content: {
                                            offsetY: 4,
                                            style: {
                                                fontSize: '32px'
                                            }
                                        }
                                    }}
                                    interactions={[
                                        {
                                            type: 'element-selected'
                                        },
                                        {
                                            type: 'element-active'
                                        },
                                        {
                                            type: 'pie-statistic-active'
                                        }
                                    ]}
                                    ref={refStatisticsTable}
                                />
                            </Space>
                        </Col>

                        <Col xl={12} lg={24}>
                            <Space direction={'vertical'} align={'center'}>
                                <Typography.Text
                                    type={'secondary'}
                                    style={{ marginLeft: -140, fontSize: '20px' }}
                                >
                                    Channels
                                </Typography.Text>
                                <Pie
                                    data={dataSource.donut_chart_channels_data}
                                    angleField={'value'}
                                    colorField={'type'}
                                    // padding={20}
                                    color={['#b7eb8f', '#fffb8f', '#ffa39e']}
                                    style={{ width: 410, marginTop: -53 }}
                                    appendPadding={[0, 20, 0, 0]}
                                    radius={1}
                                    innerRadius={0.6}
                                    label={{
                                        type: 'inner',
                                        offset: '-50%',
                                        style: {
                                            textAlign: 'center'
                                        },
                                        autoRotate: false,
                                        content: '{value}'
                                    }}
                                    statistic={{
                                        title: {
                                            offsetY: -4,
                                            style: {
                                                fontSize: '18px'
                                            }
                                        },
                                        content: {
                                            offsetY: 4,
                                            style: {
                                                fontSize: '32px'
                                            }
                                        }
                                    }}
                                    interactions={[
                                        {
                                            type: 'element-selected'
                                        },
                                        {
                                            type: 'element-active'
                                        },
                                        {
                                            type: 'pie-statistic-active'
                                        }
                                    ]}
                                    ref={refStatisticsTable}
                                />
                            </Space>
                        </Col>
                    </Row>
                ) : (
                    <Spin />
                )}
            </ConfigProvider>
        </PageContainer>
    );
};

export default Statistics;
