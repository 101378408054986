import axiosClient from "../api/axiosClient";
import {notification} from "antd";

const downloadSpreadsheet = async (fileId: string, fileName: string, mimeType: string) => {
    try {

        const file = await axiosClient.portal.get(`/api/files/download/spreadsheet/${fileId}`, {
            params:{
                mimeType: mimeType,
            },
            responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([file.data]));
        const link = document.createElement('a');
        link.href = url;

        // todo check if fileName contains ".xlsx".
        //  If true, do NOT add ".xlsx" extension.
        //  If false, add ".xlsx" extension at the end of downloadedFileName.
        // const downloadedFileName = `${fileName}` + '.xlsx';
        const downloadedFileName = `${fileName}`;

        link.setAttribute('download', downloadedFileName);
        document.body.appendChild(link);

        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading file:', error);
        // Handle the error, e.g., show a notification to the user
        notification.error({ message: 'Error downloading file' });
    }
}

export default downloadSpreadsheet;