import * as React from "react";
import {ActionType, ModalForm, ProForm, ProFormText} from "@ant-design/pro-components";
import {Button, notification, Tooltip} from "antd";
import {EditTwoTone} from "@ant-design/icons";
import axiosClient from "../../api/axiosClient";

type Props = {
    project_pretty_id?: string;
    project_pretty_title?: string;
    refetch: any;
};

const EditInvoice = (props: Props) => {

    return (
        <ModalForm
            title='Edit Project Name'
            width={'360px'}
            trigger={
                <Tooltip title='Edit' color={'#595959'}>
                    {/*<SettingTwoTone style={{fontSize: '20px'}}/>*/}
                    <Button style={{fontSize: '16px'}} type={'text'} icon={<EditTwoTone/>}/>
                </Tooltip>
            }
            autoFocusFirstInput
            onFinish={async (values) => {
                // console.log(props, values.project_pretty_title)
                // const res = await axiosClient.portal.put(`/api/projects/prettytitle/`, values);
                await axiosClient.brands
                    // .put(`/api/projects/prettytitle/${props.project_pretty_id}`, {
                    .put(`/api/projects/prettytitle`, undefined, {
                        params: {
                            pretty_id: props.project_pretty_id,
                            pretty_title: values.project_pretty_title
                        }
                    })
                    .then(() => {
                        notification.success({message: 'Success'});
                        props.refetch()
                    })
                    .catch((e) => {
                        console.error(e);
                    });
                return true;
            }}
        >
            <ProForm.Group>
                <ProFormText
                    width='md'
                    name='project_pretty_title'
                    label='Case'
                    // tooltip = "up to 24 digits"
                    placeholder={'Please enter the new project title'}
                    initialValue={props.project_pretty_title}
                />
            </ProForm.Group>
        </ModalForm>
    );
};

export default EditInvoice;