import { useAtomValue } from 'jotai';
import React, { Suspense, useContext, useEffect, useRef } from 'react';
import {
    FiltersType,
    isInvoiceTableEnabledAtom,
    projectsOnExecutionUnfilteredAtom,
    scopeAtom,
    submittedFiltersAtom,
    useInvoicesFilteredQuery,
    useInvoicesIncomingAtomValue,
    useInvoicesIncomingFilteredQuery
} from '../../atoms/internalDashboardAtoms';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import { Models } from '../../common/models';
import { ActionType, ProCard, ProFormSelect } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Button, Card, Input, Space, Spin, Statistic, Tabs, Tag, Typography } from 'antd';
import { EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { useInvoicesIncoming } from '../../query_hooks/useInvoices';
import { i } from '@tanstack/query-core/build/legacy/queryClient-IoYjXsnV';
import currencyFormatter from '../../utils/currency_formatter';
import TabPane from 'antd/es/tabs/TabPane';
import EditFile from '../EditFile';
import EditInvoice from './EditInvoice';
import { GlobalStateContext } from '../../context/GlobalContext';
import {useAtom} from "jotai/index";

type Props = {
    filters: FiltersType;
};

// const InvoicesTable = ({ filters }: Props) => {
const InvoicesTable = () => {
    // New
    const [submittedFilters, setSubmittedFilters] = useAtom(submittedFiltersAtom);
    const { data: invoicesData, refetch } = useInvoicesFilteredQuery(submittedFilters);



    const scope = submittedFilters.metricType === 'Dm' ? 'outgoing' : 'incoming';
    // Old
    // const { data: invoicesData, refetch } = useInvoicesFilteredQuery(filters);
    // const scope = filters.metricType === 'Dm' ? 'outgoing' : 'incoming';

    const { data: projectData } = useAtomValue(projectsOnExecutionUnfilteredAtom);
    // console.log('projectData in Invoices Table component: ', projectData);


    const incomingColumns: ProColumns<Models.Brands.LoggedInvoiceIncoming>[] = [
        // {
        //     title: 'Invoice ID',
        //     dataIndex: 'id',
        //     sorter: (a, b) => a.id - b.id
        // },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            formItemProps: { label: 'Invoice No' },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder={`Search Invoice`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type='primary'
                            onClick={() => {
                                confirm();
                            }}
                            icon={<SearchOutlined />}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters(); // re ti kanoume
                                setSelectedKeys([]);
                                confirm();
                                close();
                            }}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Clear
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            onFilter: (value, record) =>
                record
                    .invoice_number!.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()),
            sorter: (a, b) => (a.invoice_number ?? '').localeCompare(b.invoice_number ?? '')
        },
        {
            title: 'Case',
            dataIndex: 'project_pretty_title',
            sorter: (a, b) => (a.project_pretty_title ?? '').localeCompare(b.project_pretty_title ?? ''),
            valueType: 'select'
        },

        // {
        //     title: 'Customer',
        //     dataIndex: 'project_pretty_id',
        //     sorter: (a, b) => (a.project_pretty_id ?? '').localeCompare(b.project_pretty_id ?? ''),
        //     valueType: 'select',
        //     fieldProps: {
        //         options: projectData.map((value) => {
        //             const buyer_name =
        //                 value.accounting_customer_id !== null
        //                     ? value.accounting_customer.name
        //                     : value.customer_id !== null
        //                     ? value.customer.name
        //                     : value.external_buyer.name;
        //
        //             return {
        //                 label: buyer_name,
        //                 value: value.project_pretty_id
        //             };
        //         }),
        //         multiple: true,
        //         showSearch: true,
        //         filterOption: (input: string, option: { label: any }) =>
        //             (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())
        //     },
        //     renderFormItem: (item, config) => {
        //         return (
        //             <ProFormSelect
        //                 {...config}
        //                 fieldProps={{
        //                     showSearch: true,
        //                     filterOption: (input, option) =>
        //                         (option?.label ?? '')
        //                             .toLocaleString()
        //                             .toLowerCase()
        //                             .includes(input.toLowerCase()),
        //                     //     popupMatchSelectWidth: true,
        //                     // mode: "multiple",
        //                     mode: 'tags',
        //                     maxTagCount: 'responsive'
        //                 }}
        //                 wrapperCol={{ span: 24 }}
        //             />
        //         );
        //     },
        //     filterIcon: (filtered: boolean) => (
        //         <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        //     ),
        //     onFilter: (value, record) =>
        //         record
        //             .invoice_number!.toString()
        //             .toLowerCase()
        //             .includes((value as string).toLowerCase())
        // },
        // {
        //     title: 'Case',
        //     dataIndex: 'project_pretty_id',
        //     sorter: (a, b) => (a.project_pretty_id ?? '').localeCompare(b.project_pretty_id ?? ''),
        //     valueType: 'select',
        //     fieldProps: {
        //         options: projectData.map((value) => ({
        //             label: `${value.title} (${value.project_pretty_id})`,
        //             value: value.project_pretty_id
        //         })),
        //         multiple: true,
        //         showSearch: true,
        //         filterOption: (input: string, option: { label: any }) =>
        //             (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())
        //     },
        //     renderFormItem: (item, config) => {
        //         return (
        //             <ProFormSelect
        //                 {...config}
        //                 fieldProps={{
        //                     showSearch: true,
        //                     filterOption: (input, option) =>
        //                         (option?.label ?? '')
        //                             .toLocaleString()
        //                             .toLowerCase()
        //                             .includes(input.toLowerCase()),
        //                     //     popupMatchSelectWidth: true,
        //                     // mode: "multiple",
        //                     mode: 'tags',
        //                     maxTagCount: 'responsive'
        //                 }}
        //                 wrapperCol={{ span: 24 }}
        //             />
        //         );
        //     },
        //     filterIcon: (filtered: boolean) => (
        //         <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        //     ),
        //     onFilter: (value, record) =>
        //         record
        //             .invoice_number!.toString()
        //             .toLowerCase()
        //             .includes((value as string).toLowerCase())
        // },
        // {
        //     title: 'Supplier',
        //     dataIndex: 'supplier',
        //     valueType: 'text',
        //     sorter: (a, b) => (a.project_pretty_id ?? '').localeCompare(b.project_pretty_id ?? ''),
        //     // hideInSearch: true,
        //     render: (_, record) => (
        //         <Typography.Text>
        //             {record.supplier_customer_id != null
        //                 ? record.customer.name
        //                 : record.supplier_external_id != null
        //                 ? record.external_supplier.name
        //                 : record.accounting_customer.name}
        //         </Typography.Text>
        //     )
        // },
        {
            title: 'Date Issued',
            dataIndex: 'date',
            valueType: 'date',
            render: (text, record, index) => {
                return dayjs(record.date).format('YYYY-MM-DD');
            },
            sorter: (a, b) => dayjs(a.date).diff(dayjs(b.date))
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            valueEnum: {
                YES: <Tag color='green'>Yes</Tag>,
                NO: <Tag color='red'>No</Tag>,
                PARTIALLY: <Tag color='yellow'>Partially</Tag>
            },
            onFilter: (value, record) => record.paid === value,
            filters: [
                {
                    text: 'Yes',
                    value: 'YES'
                },
                {
                    text: 'Partially',
                    value: 'PARTIALLY'
                },
                {
                    text: 'No',
                    value: 'NO'
                }
            ]
        },
        {
            title: 'Value',
            dataIndex: 'cost',
            align: 'right',
            renderText(text, record, index, action) {
                return currencyFormatter.format(record.cost);
            },
            sorter: (a, b) => a.cost - b.cost
        },
        {
            title: 'Value + VAT',
            dataIndex: 'cost_incl_vat',
            align: 'right',
            renderText(text, record, index, action) {
                return currencyFormatter.format(record.cost_incl_vat);
            },
            sorter: (a, b) => a.cost_incl_vat - b.cost_incl_vat
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paid_amount',
            align: 'right',
            renderText(text, record, index, action) {
                return currencyFormatter.format(record.paid_amount);
            },
            sorter: (a, b) => a.paid_amount - b.paid_amount
        },
        {
            title: '',
            dataIndex: 'edit',
            editable: false,
            align: 'center',
            width: '8%',
            render: (text, record, _, action) => [
                <EditInvoice
                    project_pretty_id={record.project_pretty_id}
                    project_pretty_title={record.project_pretty_title}
                    refetch={refetch}
                    key={record.project_pretty_id}
                />
            ]
        }
    ];

    const outgoingColumns: ProColumns<Models.Brands.LoggedInvoiceOutgoing>[] = [
        {
            title: 'Invoice ID',
            dataIndex: 'id',
            sorter: (a, b) => a.id - b.id
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            formItemProps: { label: 'Invoice No' },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder={`Search Invoice`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type='primary'
                            onClick={() => {
                                confirm();
                            }}
                            icon={<SearchOutlined />}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters(); // re ti kanoume
                                setSelectedKeys([]);
                                confirm();
                                close();
                            }}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Clear
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            onFilter: (value, record) =>
                record
                    .invoice_number!.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()),
            sorter: (a, b) => (a.invoice_number ?? '').localeCompare(b.invoice_number ?? '')
        },
        {
            title: 'Case',
            dataIndex: 'project_pretty_id',
            sorter: (a, b) => (a.project_pretty_id ?? '').localeCompare(b.project_pretty_id ?? ''),
            valueType: 'select',
            fieldProps: {
                options: projectData.map((value) => ({
                    label: `${value.title} (${value.project_pretty_id})`,
                    value: value.project_pretty_id
                })),
                multiple: true,
                showSearch: true,
                filterOption: (input: string, option: { label: any }) =>
                    (option?.label ?? '').toLocaleString().toLowerCase().includes(input.toLowerCase())
            },
            renderFormItem: (item, config) => {
                return (
                    <ProFormSelect
                        {...config}
                        fieldProps={{
                            showSearch: true,
                            filterOption: (input, option) =>
                                (option?.label ?? '')
                                    .toLocaleString()
                                    .toLowerCase()
                                    .includes(input.toLowerCase()),
                            //     popupMatchSelectWidth: true,
                            // mode: "multiple",
                            mode: 'tags',
                            maxTagCount: 'responsive'
                        }}
                        wrapperCol={{ span: 24 }}
                    />
                );
            },
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            onFilter: (value, record) =>
                record
                    .invoice_number!.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase())
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            valueType: 'text',
            sorter: (a, b) => (a.project_pretty_id ?? '').localeCompare(b.project_pretty_id ?? ''),
            // hideInSearch: true,
            render: (_, record) => (
                <Typography.Text>
                    {record.customer_id != null
                        ? record.customer.name
                        : record.external_buyer_id != null
                            ? record.external_buyer.name
                            : record.accounting_customer.name}
                </Typography.Text>
            )
        },
        {
            title: 'Date Issued',
            dataIndex: 'date',
            valueType: 'date',
            render: (text, record, index) => {
                return dayjs(record.date).format('YYYY-MM-DD');
            }
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            valueEnum: {
                YES: <Tag color='green'>Yes</Tag>,
                NO: <Tag color='red'>No</Tag>,
                PARTIALLY: <Tag color='yellow'>Partially</Tag>
            },
            onFilter: (value, record) => record.paid === value,
            filters: [
                {
                    text: 'Yes',
                    value: 'YES'
                },
                {
                    text: 'Partially',
                    value: 'PARTIALLY'
                },
                {
                    text: 'No',
                    value: 'NO'
                }
            ]
        },
        {
            title: 'Value',
            dataIndex: 'cost',
            align: 'right',
            renderText(text, record, index, action) {
                return currencyFormatter.format(record.cost);
            },
            sorter: (a, b) => a.cost - b.cost
        },
        {
            title: 'Value + VAT',
            dataIndex: 'cost_incl_vat',
            align: 'right',
            renderText(text, record, index, action) {
                return currencyFormatter.format(record.cost_incl_vat);
            },
            sorter: (a, b) => a.cost_incl_vat - b.cost_incl_vat
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paid_amount',
            align: 'right',
            renderText(text, record, index, action) {
                return currencyFormatter.format(record.paid_amount);
            },
            sorter: (a, b) => a.paid_amount - b.paid_amount
        }
    ];

    if (scope === 'incoming') {
        return (
            <ProTable
                columns={incomingColumns}
                dataSource={invoicesData}
                // request={async () => {
                //     const { data } = useInvoicesIncomingAtomValue();
                //     return data;
                // }}
                pagination={{
                    pageSize: 10
                }}
                toolBarRender={false}
                search={false}
            />
        );
    }
    if (scope === 'outgoing') {
        return (
            <ProTable
                columns={outgoingColumns}
                dataSource={invoicesData}
                // request={async () => {
                //     const { data } = useInvoicesIncomingAtomValue();
                //     return data;
                // }}
                pagination={{
                    pageSize: 10
                }}
                toolBarRender={false}
                search={false}
            />
        );
    }
    return <></>;
};

// const Statistics = ({ filters }: Props) => {
const Statistics = () => {
    // New
    const [submittedFilters, setSubmittedFilters] = useAtom(submittedFiltersAtom);
    // console.log('filters in Statistics component: ', submittedFilters);
    const { data: invoicesData } = useInvoicesFilteredQuery(submittedFilters);
    // console.log('invoicesData in Statistics component: ', invoicesData);

    // Old
    // console.log('filters in Statistics component: ', filters);
    // const { data: invoicesData } = useInvoicesFilteredQuery(filters);




    // const enabled = useAtomValue(isInvoiceTableEnabledAtom);
    // const scope = filters.metricType === 'Dm' ? 'outgoing' : 'incoming';
    type LoggedInvoice = Models.Brands.LoggedInvoiceIncoming | Models.Brands.LoggedInvoiceOutgoing;

    const total = invoicesData.reduce((total: number, datum: LoggedInvoice) => total + datum.cost, 0);
    const total_with_vat = invoicesData.reduce(
        (total: number, datum: LoggedInvoice) => total + datum.cost_incl_vat,
        0
    );
    const total_paid_amount = invoicesData.reduce(
        (total: number, datum: LoggedInvoice) => total + datum.paid_amount,
        0
    );

    return (
        <ProCard split='vertical'>
            <ProCard>
                <Statistic title='Total Net Value' value={currencyFormatter.format(total)} />
            </ProCard>
            <ProCard>
                <Statistic title='Total Value + VAT' value={currencyFormatter.format(total_with_vat)} />
            </ProCard>
            <ProCard>
                <Statistic title='Total Paid + VAT' value={currencyFormatter.format(total_paid_amount)} />
            </ProCard>
            <ProCard>
                <Statistic
                    title='Total Unpaid + VAT'
                    value={currencyFormatter.format(total_with_vat - total_paid_amount)}
                />
            </ProCard>
        </ProCard>
    );
};

// const BrandsInvoicesList = ({ filters }: Props) => {
const BrandsInvoicesList = () => {

    // const [submittedFilters, setSubmittedFilters] = useAtom(submittedFiltersAtom);
    // const { data: invoicesData } = useInvoicesFilteredQuery(submittedFilters);
    // console.log('invoicesData in Statistics component: ', invoicesData);

    // if (invoicesData == null) {
    //     return <></>;
    // }

    // New
    // const [submittedFilters, setSubmittedFilters] = useAtom(submittedFiltersAtom);
    // console.log("submittedFilters.startDate: ", submittedFilters.startDate)
    // console.log("submittedFilters.endDate: ", submittedFilters.endDate)
    // console.log("submittedFilters.customers: ", submittedFilters.customers)
    // console.log("submittedFilters.internalSuppliers: ", submittedFilters.internalSuppliers)
    // console.log("submittedFilters.externalSuppliers: ", submittedFilters.externalSuppliers)
    // console.log("submittedFilters.internalCustomers: ", submittedFilters.internalCustomers)
    // console.log("submittedFilters.externalCustomers: ", submittedFilters.externalCustomers)
    // console.log("submittedFilters.metricType: ", submittedFilters.metricType)
    // console.log("submittedFilters.customers: ", submittedFilters.customers)

    const enabled = useAtomValue(isInvoiceTableEnabledAtom);
    if (!enabled) {
        return <></>;
    }



    // if (!enabled || invoicesData == null) {
    //     return <></>;
    // }

    // console.log("DisabledInvoices Table!!!")

    return (
        <Tabs>
            <TabPane tab={'Brands Invoices'}>
                <ProCard split='horizontal' ghost>
                    <ProCard style={{ marginBottom: '1%' }} subTitle={'Totals'}>
                        {/*<Statistics filters={filters} />*/}
                        {/*<Statistics filters={submittedFilters} />*/}
                        <Statistics />
                    </ProCard>
                    <ProCard>
                        {/*<InvoicesTable filters={filters} />*/}
                        {/*<InvoicesTable filters={submittedFilters} />*/}
                        <InvoicesTable />
                    </ProCard>
                </ProCard>
            </TabPane>
        </Tabs>
    );
};

export default BrandsInvoicesList;