import {useQuery} from "react-query";
import {InvoicesDonutPropsType} from "../../src/atoms/internalDashboardAtoms";
import axiosClient from "../api/axiosClient";

export function UseInvoiceDonutQuery (props: InvoicesDonutPropsType) {
    return useQuery( {
        queryKey: ["charts", "donut", "invoices", props],
        queryFn: async () =>
            await axiosClient.portal.post('/api/graphs/donut/invoices', props)
                .then(({data}) => data)
                .catch((reason) => console.error(reason)),
        suspense: true,
    })
}