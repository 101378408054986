import * as React from 'react';
import { useRef } from 'react';
import { Button, Tooltip } from 'antd';
import { ModalForm } from '@ant-design/pro-components';
import { DeleteOutlined } from '@ant-design/icons';
import type { ActionType } from '@ant-design/pro-components';
import { postRequest } from '../api/postRequest';
import axiosClient from '../api/axiosClient';

type Props = {
    id: string;
    name: string;
    refStatisticsReportsTable: React.RefObject<ActionType>;
    refStatisticsChannelsTable: React.RefObject<ActionType>;
    refStatisticsTable: React.MutableRefObject<ActionType | undefined>;
};

const DeleteReport = (props: Props) => {
    // const StatisticsReportsTableRef = useRef<ActionType>();

    return (
        <ModalForm
            width={400}
            title={'Delete Report'}
            trigger={
                <Tooltip title='Delete' color={'#595959'}>
                    <Button
                        type={'text'}
                        icon={<DeleteOutlined />}
                        danger
                        // icon={<DeleteOutlined/>}
                    />
                </Tooltip>
            }
            submitter={{ submitButtonProps: { danger: true } }}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                okText: 'Delete',
                cancelText: 'No'
            }}
            onFinish={async (values) => {
                return axiosClient.portal
                    .post(`/api/reports/delete/${props.id}`, {
                        name: props.name
                    })
                    .then((res) => {
                        props.refStatisticsReportsTable?.current?.reload();
                        props.refStatisticsChannelsTable?.current?.reload();
                        props.refStatisticsTable?.current?.reload();

                        return true;
                    })
                    .catch((reason) => false);
            }}
        >
            Are you sure you want to delete this report?
        </ModalForm>
    );
};

export default DeleteReport;
