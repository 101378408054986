import React, { createContext, useContext } from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { MyLayout } from './components/MyLayout';
import PrivateRoutes from './components/PrivateRoutes';
import { UserLogin } from './pages/UserLogin';
import ForgotPassword from './pages/ForgotPassword';
import NoFoundPage from './pages/404';
import Authorizer from './api/Authorizer';
import { GlobalStateContext } from './context/GlobalContext';
import { PageLoading } from '@ant-design/pro-layout';
import FileApproval from './components/FileApproval';
import GenerateReports from './components/GenerateReports';
import Statistics from './components/Statistics';
import PerformanceReports from './components/PerformanceReports';
import FinancialReports from './components/FinancialReports';
import InternalDashboard from './components/InternalDashboard';
import Dashboard from './components/Dashboard';
import { UserAuth } from './context/AuthContext';
import UnauthorizedPage from './pages/403';
import { Access } from './components/Access';
import CustomerInvoices from './components/CustomerInvoices';

export const AuthorizerContext = createContext<Authorizer | undefined>(undefined);

function App() {
    const location = useLocation();
    const background = location.state && location.state.background;
    const { authorizing } = useContext(GlobalStateContext);
    const { can, user } = UserAuth();

    if (authorizing) {
        return <PageLoading />;
    }

    return (
        <div className='App'>
            <Routes location={background || location}>
                <Route element={<PrivateRoutes />}>
                    <Route element={<MyLayout />}>
                        {/*////////////////*/}
                        {/*//// Routes ////*/}
                        {/*////////////////*/}
                        <Route
                            path='/dashboard'
                            element={
                                <Access
                                    accessible={can('read', 'portal::data::customer::finance')}
                                    fallback={
                                        <>
                                            <UnauthorizedPage />
                                        </>
                                    }>
                                    <Dashboard />
                                </Access>
                            }
                        />

                        <Route
                            path='/internaldashboard'
                            element={
                                <Access
                                    accessible={can('read', 'portal::data::manager')}
                                    fallback={
                                        <>
                                            <UnauthorizedPage />
                                        </>
                                    }>
                                    <InternalDashboard />
                                </Access>
                            }
                        />

                        <Route
                            path='/fileapproval'
                            element={
                                <Access
                                    accessible={can('read', 'portal::data::manager')}
                                    fallback={
                                        <>
                                            <UnauthorizedPage />
                                        </>
                                    }>
                                    <FileApproval />
                                </Access>
                            }
                        />

                        <Route
                            path='/management/generatereports'
                            element={
                                <Access
                                    accessible={can('read', 'management::reporting::data')}
                                    fallback={
                                        <>
                                            <UnauthorizedPage />
                                        </>
                                    }>
                                    <GenerateReports />
                                </Access>
                            }
                        />

                        <Route
                            path='/management/statistics'
                            element={
                                <Access
                                    accessible={can('read', 'management::reporting::data')}
                                    fallback={
                                        <>
                                            <UnauthorizedPage />
                                        </>
                                    }>
                                    <Statistics />
                                </Access>
                            }
                        />

                        <Route
                            path='/reports/performance'
                            element={
                                <Access
                                    accessible={can('read', 'portal::data::customer::performance')}
                                    fallback={
                                        <>
                                            <UnauthorizedPage />
                                        </>
                                    }>
                                    <PerformanceReports />
                                </Access>
                            }
                        />

                        <Route
                            path='/reports/financial'
                            element={
                                <Access
                                    accessible={can('read', 'portal::data::customer::finance')}
                                    fallback={
                                        <>
                                            <UnauthorizedPage />
                                        </>
                                    }>
                                    <FinancialReports />
                                </Access>
                            }
                        />

                        {/*//////////////////*/}
                        {/*//// Navigate ////*/}
                        {/*//////////////////*/}
                        <Route
                            path='/'
                            element={
                                can('read', 'portal::data::customer::finance') ? (
                                    <Navigate to={'/dashboard'} />
                                ) : can('read', 'portal::data::customer::performance') ? (
                                    <Navigate to={'/reports/performance'} />
                                ) : can('read', 'portal::data::manager') ? (
                                    <Navigate to={'/internaldashboard'} />
                                ) : (
                                    <UnauthorizedPage />
                                )
                            }
                        />
                        <Route path='reports' element={<Navigate to={'/reports/performance'} />} />
                        <Route path='management' element={<Navigate to={'/management/statistics'} />} />
                        {/* <Route path='invoices' element={<Navigate to={'/invoices'} />} /> */}

                        {/*<Route path='/' element={*/}
                        {/*    <Access accessible={can('read', 'portal::data::customer::finance')} fallback={<></>}>*/}
                        {/*        <Dashboard/>*/}
                        {/*    </Access>*/}
                        {/*}/>*/}

                        {/*<Access accessible={can('read', 'portal::data::customer::performance')} fallback={<></>}>*/}
                        {/*    <PerformanceReports/>*/}
                        {/*</Access>*/}
                    </Route>
                </Route>
                <Route path='/login' element={<UserLogin />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='*' element={<NoFoundPage />} />
            </Routes>
        </div>
    );
}

export default App;
