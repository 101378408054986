import { useQuery } from 'react-query';
import axiosClient from '../api/axiosClient';

export default function useCurrencyMap() {
    return useQuery({
        queryKey: ['currency_map'],
        queryFn: () => axiosClient.main.get('/api/tools/currencies'),
        select: (res) => Object.fromEntries(res.data.map((datum: any) => [datum.id, datum.extra.trim()])),
	staleTime: 10 * 60 * 60 * 1000 // 10 Hours
    });
}
