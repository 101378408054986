import { memo } from 'react';
import {
    RevenuesByChannelType,
    RevenuesByLabelType,
    RevenuesByOwnerType,
    RevenuesByProductType,
    InvoicesByProjectType
} from '../InternalDashboard';
import { ProCard } from '@ant-design/pro-components';
import { Column } from '@ant-design/plots';
import { Row, Statistic } from 'antd';
import dayjs from 'dayjs';
import useCurrencyMap from '../../query_hooks/useCurrencyMap';

const ColumnGraph = memo(function MonthColumn({
    dataSet,
    annotations,
    seriesField,
    statisticTitle,
    loading
}: ColumnGraphProps) {
    const { data: currencyMap, isLoading: currencyMapLoading } = useCurrencyMap();

    if (loading || currencyMapLoading || !dataSet)
        return (
            <ProCard ghost>
                <Column xField={'x'} yField={'y'} data={[{ x: 1, y: 1 }]} loading />
            </ProCard>
        );

    return (
        <>
            <Row justify={'end'}>
                <Statistic
                    title={statisticTitle ?? 'Total Owned Revenue'}
                    value={dataSet.total}
                    precision={2}
                    groupSeparator={'.'}
                    decimalSeparator={','}
                    suffix={currencyMap![dataSet.currency]}
                />
            </Row>
            <br />
            <Column
                isStack={true}
                xField='month'
                yField='revenue'
                seriesField={seriesField}
                appendPadding={[20, 0, 0, 0]}
                data={dataSet.data}
                yAxis={{
                    minLimit: 0,
                    label: {
                        formatter: (value) => {
                            let formNumber = new Intl.NumberFormat('en-US', {
                                notation: 'compact'
                            });
                            // return formNumber.format(+value) + ' ' + currencyMap[dataSource.totalRevenuesByOwner.currency]
                            return currencyMap![dataSet.currency] + ' ' + formNumber.format(+value);
                        }
                    }
                }}
                tooltip={{
                    domStyles: {
                        'g2-tooltip': { 'overflow-y': 'auto', 'max-height': '320px' }
                    },
                    formatter: (datum) => {
                        let finalValue = Math.round((datum['revenue'] as number) * 100) / 100;
                        return {
                            name: datum[seriesField],
                            value:
                                (finalValue?.toLocaleString('el-GR') || 0) +
                                ' ' +
                                currencyMap![dataSet.currency]
                        };
                    }
                }}
                maxColumnWidth={100}
                // Format month in column graph and annotations
                meta={{
                    month: {
                        formatter: (value) => dayjs(value, 'YYYY-MM').format('MMM YY')
                    }
                }}
                // annotations={annotations ? annotations['totalRevenuesByOwner'] : []}
                annotations={annotations}
                interactions={[{ type: 'legend-filter', enable: false }]}
            />
        </>
    );
});

type ColumnGraphProps = {
    dataSet?: RevenuesByChannelType | RevenuesByLabelType | RevenuesByOwnerType | RevenuesByProductType | InvoicesByProjectType | any;
    annotations: any[];
    seriesField: string;
    statisticTitle?: string;
    loading?: boolean;
};

export default ColumnGraph;
