import React, { Component, useRef } from 'react';
import { Anchor, Avatar, Image, ConfigProvider, notification, Tag, Tooltip } from 'antd';
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { EditableProTable } from '@ant-design/pro-table';
import enUSIntl from 'antd/es/locale/en_US';
import { CheckCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { postRequest } from '../api/postRequest';
import { StatisticsReports } from './StatisticsReports';
import axiosClient from '../api/axiosClient';

export interface IStatisticsChannelsProps {
    //Here we pass the Props Interface
    customer_id: number;
    date_statistics: string;
    refStatisticsTable: React.MutableRefObject<ActionType | undefined>;
}

export interface IStatisticsChannelsState {
    //Here we pass the State Interface

    //? means it can be undefined
    editableKeys: any;
    dataSource?: Channel[];
    // loading: boolean
    refStatisticsChannelsTable: React.RefObject<ActionType>;
}

export type Channel = {
    id: string;
    image_url: string;
    status: string;
};

export class StatisticsChannels extends Component<IStatisticsChannelsProps, IStatisticsChannelsState> {
    constructor(props: IStatisticsChannelsProps) {
        super(props);

        this.state = {
            editableKeys: [],
            dataSource: undefined,
            // loading: false,
            refStatisticsChannelsTable: React.createRef<ActionType>()
        };
    }

    componentWillMount() {}

    // After the component did mount, we set the state.
    //On component start
    componentDidMount() {}

    columns: ProColumns<Channel>[] = [
        {
            title: 'Image',
            dataIndex: 'image_url',
            align: 'center',
            render: (dom, entity) => {
                return <Avatar src={<Image placeholder src={entity.image_url} style={{ width: 32 }} />} />;
            }
        },
        {
            title: 'Channel ID',
            dataIndex: 'id',
            align: 'center',
            render: (dom, entity) => {
                return (
                    <Tooltip title='View' color={'#595959'}>
                        <a
                            href={'https://www.youtube.com/channel/' + entity.id}
                            target='_blank'
                            rel='noreferrer'
                            style={{ color: '#808080' }}
                        >
                            {entity.id}
                        </a>
                    </Tooltip>
                );
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            render: (dom, entity) => {
                if (entity.status == 'approved') {
                    return (
                        <Tag icon={<CheckCircleOutlined />} color='success'>
                            Approved
                        </Tag>
                    );
                } else if (entity.status == 'unapproved') {
                    return (
                        <Tag icon={<ExclamationCircleOutlined />} color='warning'>
                            Not Approved
                        </Tag>
                    );
                } else {
                    return (
                        <Tag icon={<CloseCircleOutlined />} color='error'>
                            Overlooked
                        </Tag>
                    );
                }
            }
        }
    ];

    render() {
        return (
            <ConfigProvider locale={enUSIntl}>
                <EditableProTable<Channel>
                    request={async (params, sort, filter) => {
                        try {
                            const res = await axiosClient.portal.post(
                                '/api/youtubechannels/getchannels/' + this.props.customer_id,
                                {
                                    dateStatistics: this.props.date_statistics
                                }
                            );
                            // console.log(res);
                            return { data: res.data.params, success: true, total: res.data.length };
                        } catch (e: any) {
                            notification.error({ message: e.response.data.message });
                            return { data: [], success: false, total: 0 };
                        }
                    }}
                    columns={this.columns}
                    rowKey='id'
                    key={this.props.customer_id + '_' + this.props.date_statistics}
                    //Το βάζω false αν δεν θέλω να προσθέτει γραμμή
                    recordCreatorProps={false}
                    expandable={{
                        expandedRowRender: (record) => (
                            <StatisticsReports
                                channel_id={record.id}
                                date_statistics={this.props.date_statistics}
                                refStatisticsChannelsTable={this.state.refStatisticsChannelsTable}
                                refStatisticsTable={this.props.refStatisticsTable}
                            />
                        )
                    }}
                    actionRef={this.state.refStatisticsChannelsTable}
                />
            </ConfigProvider>
        );
    }
}
